import styled from 'styled-components';

import { CustomListItem, MenuList } from '../NavigationBarV2/styles'
import BackgroundImage from '/public/images/WellnessPage/awareness.png';
import Scurve from '/public/images/WellnessPage/scurve.svg';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';
const ExtraBoldFont = 'Rubik-Bold';
const MontserratRegular = 'Montserrat-Regular';
const MontserratSemiBold = 'Montserrat-SemiBold';
const TopPanel = styled.div`
  display:flex;
  flex-direction:row;
  width: 100%;
  height: 100%;
  padding: 0px 0 0 117px;
  @media(min-width: 1366px){
    padding-top: 0px;
  }
  > div:first-child{
    flex:1;
    display: flex;
    padding-top: 0px;
    padding-bottom: 33px;
    padding-left: 45px;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 55px;
    padding-right: 70px;
     > p {
        font-style: normal;
        font-size: 18px;
        line-height: 110.5%;
        color: #F6B479;
        letter-spacing: 0px;
        margin-top: 10px;
        margin-bottom: 11px;
        font-family: ${MontserratSemiBold}
     }
    > h1 {
        font-size: 48px;
        margin: 0;
        color: #24395a;
        font-family: Rubik-Medium;
        font-style: normal;
        letter-spacing: 0;
        line-height: 110.5%;
        font-weight: bold;
        word-break: break-word;
        width: 100%;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 30px;
        >img{
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 70px;
          height: 4px;
        }
    }
    >img{
      width: 75px;
      height: 5px;
    }
    >Button{
      margin-left:0;
      background-color: #F6B479;
    }
  }
  > div:last-child{
    display: flex;
    align-items: center;
    flex: 2;
    width: 100%;
    height: 100%;
    @media(max-width: 1366px){
      flex: 2;
    }
    > picture {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }
    img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        @media(min-width: 1420px) {
          width: 100%;
          height: 100%;
        }
        @media(min-width: 1500px) {
          width: 100%;
          height: 100%;
        }
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0;
    > div:first-child{
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
     > h1 {
        font-size: 36px;
        margin: 0;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 57px;
        font-weight: 800;
        line-height: 57px;
        word-break: break-word;
        width: 100%;
    } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        > picture {
          justify-content: center;
        }
        img {
            width: 80%;
        }
    }
  }
  @media (max-width: 500px) {
  >div:first-child {
        width: 100%;
        padding: 30px 10px;
        margin-bottom: 20px;
    > h1 {
      margin: 0;
      color: #404555;
      font-family: ${BoldFont};
      letter-spacing: 0;
      font-weight: 600;
      line-height: 36px;
      word-break: break-word;
      width: 100%;
      padding-left: 20px;
      font-size: 36px;
      margin-bottom: 20px;
    }
    > p {
        font-family: ${MontserratSemiBold};
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 25px;
        padding-left: 20px;
        font-weight: 600;
        font-size: 16px;
     }
     >img{
      padding-left: 20px;
    }
     >button{
        width: 315px;
        font-size: 18px;
        line-height: 21px
        margin-top: 10px;
        margin: 0 auto;
      } 
    }
    >div:last-child{
      padding: 0;
      margin-top: 0;
      >picture{
        width:100%;
        >img{
          width: 375px;
          height: 280px;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
  }
`;
const Button = styled.button`
  width: ${(props) => props.tryFree ? '190px' : '190px'};
  height:50px;
  background-color: ${(props) => props.tryFree ? '#fff' : '#F6797D'};
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff'};
  display: ${({ display }) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 20px;
  line-height: 24px;
  font-family: Rubik-Medium;
  font-style: normal;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 500px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;

const TopPanelContent = styled.div`
  >p{
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: #24395a;
    margin: 30px 0 40px 0;
    font-family: ${MontserratRegular};
  }
    @media (max-width: 500px) {
    > p {
        font-family: ${MediumFont};
        font-size: 15px;
        line-height: 26px;
        color: #404555;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 25px;
        padding-left: 20px;
     } 
  }
`;

const MiddlePanel = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    float: left;
    width: 50%;
    flex-direction: column;
    
    img {
        width: 200px;
        float: right;
    }
      
  }
  > div:last-child{
    width: 50%;
    padding: 30px 70px 0 60px;
    float: left;
    > p {
        font-family: ${RegularFont};
        font-size: 20px;
        line-height: 30px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 28px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    }

  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
        img {
           width: 300px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        padding: 0 62px;
        align-items: center;
    }
  }
  
@media (max-width: 500px) {
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
       img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        } 
    }
    > div:last-child{
        width: 100%;
        align-items: center;
        padding: 11px;
        margin: 0 auto;
        display: flex;
        > p {
        font-family: ${RegularFont};
        font-size: 15px;
        line-height: 15px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding: 0; 
     }
    > h1 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 250;
        word-break: break-word;
        width: 100%;
        padding: 0px;
    }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;
const MiddlePanelSecond = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    float: left;
    width: 50%;
    padding: 30px 23px 0 107px;
    flex-direction: column;
    > p {
        font-family: ${RegularFont};
        font-size: 20px;
        line-height: 30px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 28px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    } 
  }
  > div:last-child{
    width: 50%;
    float: left;
    img {
        width: 200px;
        float: left;
    }
    
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width: 500px) {
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
        padding: 11px;
            > p {
        font-family: ${RegularFont};
        font-size: 15px;
        line-height: 15px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
     }
    > h1 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 250;
        word-break: break-word;
        width: 100%;
        padding: 0px;
    } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const CustomListItemV2 = styled(CustomListItem)`
  border-top: ${({ active }) => active ? '5px solid #F6797D' : '5px solid transparent'};
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0px;
  margin: 0px 14px 0px 14px;
  >a{
    font-size: 20px;
  }
  &:hover, &:focus, &:active {
    border-top: 5px solid #F6797D;
    border-bottom: none;
    >a{
      font-size: 20px;
    }
  }
  @media (max-width: 1200px) {
    min-width: unset;
    >a{
      font-size: 16px;
    }
  }
`;
const MenuListV2 = styled(MenuList)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 1200px) {
    position: unset;
    width: 100%;
    box-shadow: none;
    background-color: none;
    padding: 0 0 10px;
  }
`;

const CoreFeatureSection = styled.div`
  width: 100%;
  display: flex;
  padding-bottom:10px;
  max-width: 1200px;
  margin: auto;
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: ${ExtraBoldFont};
    }
    >h2{
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: ${ExtraBoldFont};
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    >div{
      text-align: center;
      >p{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      >h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
      }
    }
  }

  @media(max-width: 1200px){
    >div{
      >h1{
        padding: 0px 10px;
      }
    }
  }
`;

const AwarenessSection = styled.div`
  width:80%;
  height:400px;
  display:flex;
  margin:30px 0;
  background: linear-gradient(180deg, #102B52 0%, #006FBC 100%);
  border-radius: 0px 0px 117px 0px;
  padding: 0 0 0 70px;
  .responsiveAwarenessButton{
    display: none;
  }
  >div:first-child{
    width:30%;
    display:flex;
    align-items:center;
    position: relative;
    left: 50px;
      >img{
        width:270px;
        position: relative;
      }
      >div{
        position: absolute;
        left: 9%;
        @media(max-width: 1400px){
          right: 32%;
        }
        font-family: ${BoldFont}
        font-size: 96px;
        line-height: 114px;
        color: #F6797D;
        >span{
          font-weight: 500;
          font-size: 28px;
          line-height: 43px;
          color: #F6797D;
        }
      }
  }
  >div:nth-child(2){
    width:65%;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    padding-left:70px;
    flex-direction: column;
    >p{
      color:#FFFFFF;
      font-size: 30px;
      line-height: 43px;
      margin-bottom:5px;
      font-family: Rubik-Medium;
      font-style: normal;
    }
    >button{
      width:190px;
      margin-top: 10px;
      margin-left: 0;
    }
    }
    @media (max-width:1000px){
      >div:first-child{
        padding: 0;
        >p{
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
    @media (max-width:700px){
      border-radius: 0;
      width: 100%;
      >div:first-child{
        >p{
          font-size: 15px;
          line-height: 25px;
        }
        >Button{
          font-size: 8px;
        }
      }
      >div:nth-child(2){
        >div{
          right: 14%;
          font-size: 50px;
        }
      }
    }
    @media (max-width:600px){
      padding: 10px;
      flex-direction: column;
      height: auto;
      .responsiveAwarenessButton{
        display: flex;
        justify-content: center;
        margin: 20px;
        >button{
          width: 315px;
        }
      }
      >div:first-child{
        width: 100%;
        height: 50%;
        padding: 10px;
        >p{
          width: 100%;
          font-weight: 500;
          font-size: 20px;
          line-height: 36px;
        }
        >button{
          display: none;
        }
      }
      >div:nth-child(2){
        margin-top: 10px;
        justify-content: center;
        width: 100%;
        height: 50%;
        >img{
          width: 200px;
        }
        >div{
          font-weight: 500;
          font-size: 65px;
          line-height: 77px;
          right: 38%;
        }
      }
    }
    @media (max-width:400px){
      >div:nth-child(2){
        >div{
          >span{
            font-size: 21px;
          }
        }
      }
    }
`;

const Resources = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:30px;
  flex-direction:${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  >div:first-child{
    .responsive{
      display:none;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-item: flex-end;
    >div:first-child{
      display: flex;
      align-items: center;
      padding-top: 20px;
      flex-direction: row;
      .title{
        font-size: 30px;
        color: ${({ col }) => col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display:inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
      } 
      >img{
        width: 60px;
        height: 60px;
      }
    }
    >div{
      width: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      cursor:pointer;
    }
    >div:nth-child(3){
      >div{
        >div:last-child{
          >img{
            width:100%;
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items:${({ align }) => align};
    align-items: flex-end;
    img {
        float: left;
        width: 500px;
        height: auto;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width:600px){
    padding:0;
    margin-top: 10px;
    >div:first-child{
      padding: 0;
      .normal{
        display:none;
      }
      .responsive{
        display:block;
      }
    }
    >div:last-div{
      display:none;
    }
    .image{
      display:none;
    }
    >div:first-child{
      >div:last-child{
        >div{
          >div{
            .responsive-image{
              >img{
                width:100%;
              }
            }
          }
        }
      }
    }
  }
`;

const MainResources = styled.div`
  width:100%;
  position:relative;
  float: left;
  margin-bottom: 20px;
  .line{
    position: absolute;
    left:0px;
    >svg{
      fill: ${({ color }) => color};
    }
  }
  >h1{
    width: 80%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395A;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
  }
  .scurve{
    float: left;
    width: 100%;
    background-image: url(${Scurve});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-90px, 90px);
    @media(max-width: 1400px){
      transform: translate(-20px,90px);
    }
  }
  @media(max-width: 500px){
    >h1{
      font-weight: 500;
      font-size: 26px;
      width: 100%;
      margin: 0 0 20px 0;
      padding-left: 20px;
    }
    .line{
      padding-left: 20px;
    }
  }
`;

const FooterSection = styled.div`
  background-image: linear-gradient(180deg, #003148 0%, #006681 100%);
  padding: 50px 90px 50px 98px;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    padding: 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  p {
    font-size: 35px;
    line-height: 40px;
    color: #fff;
    font-family: ${RegularFont}
    font-weight: 400;
    text-align: center;
    margin: 20px 0px;
  }
  }

  @media (max-width: 500px) {
   text-align: center;
   > div{
      padding: 10px 0px;
    p {
      font-size: 23px;
    }
   }
  }
`;

const RatingsContainer = styled.div`
  background: #EDF7FF;
  width: 100%;
  position: relative;
  margin: 43px 0;
  margin-bottom:0;
  padding-bottom:0;
  border-radius: 6px;
  text-align: center;
  float:left;
  display: flex;
  align-items: center;
  flex-direction: column;
  >img{
    width: 106px;
    height: 106px;
    border-radius: 50%;
    position: absolute;
    top: -52.8px;
    left: 45%;
  }
  .svgContainer{
    margin: 80px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    >svg{
      fill: #F8B739;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .ratingDescription{
    font-family: ${MediumFont}
    font-size: 18px
    line-height: 26px
    text-align: Center
    color: #000000;
    padding: 0 50px 30px;
    width: 80%;
    font-family: ${MontserratRegular};
    @media(max-width: 1000px){
      padding: 0;
    }
  }
  .ratingBy{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    tex-align: Center
    margin-top: 0px;
    color: #3a3a3a;
    margin-bottom: 40px;
  }
`;
const ResourcesWrapper = styled.div`
  width: 100%;
  padding-bottom: 100px;
  float:left;
  @media(max-width: 800px){
    padding: 0 10px;
  }
`;
const RowWrapper = styled.div`
  width: 100%;
  padding: ${({ selected }) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({ selected, bgColor }) => selected ? bgColor : '#fff'};
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >img{
  width: 35px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  }
  >div{
  width: calc(100% - 70px);
  font-size: 24px;
  font-family: ${({ customColor }) => customColor ? `${BoldFont}` : `${MediumFont}`} ;
  color: #24395A;
  }
  .downArrow{
  width: 20px;
  transform: ${({ selected }) => selected && 'rotateX(180deg)'};
  >img{
  width: 14px;
  cursor: pointer;
  }
  }
  }
  .expand{
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  font-family: ${MontserratRegular};;
  font-style: normal;
  color: #3A3A3A;
  cursor: pointer;
  }
  @media(max-width:500px){
  display:none;
  }
`;

const WellBeing = styled.div`
  width:100%;
  display:flex;
  height: 281px;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 12px;
  justify-content: center;
  >div{
    width:43%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 281px;
  }
  >div:first-child{
    justify-content:flex-start;
    height: 281px;
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 40px;
      line-height: 47px;
      color: #FFFFFF;
      margin-bottom: 20px;
      @media(max-width: 500px){
        font-size: 30px;
        line-height: 36px;
      }
  }
}
@media (max-width: 1200px) {
  flex-direction: column;
  text-align: center;
  padding : 0;
  >div:first-child{
    width: 100%;
    justify-content: center;
  }
  >div:last-child{
    width: 100%;
    flex-direction: column;
    margin-bottom: 35px;
    >button:last-child{
      margin-top: 5px;
    }
  }
}
@media(max-width: 500px){
  background-image: url("/images/WellnessPage/Group 602.png");
  border-radius: 0px;
  width: 100%;
  height: 335px;
  >div:first-child{
    margin-top: 35px;
  }
}
`;

const ButtonGetStarted = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '236px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#253A5B'};
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff'};
  display: ${({ display }) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 700px) {
    margin-bottom: 5px;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin-left: 2px;
  }
`;

const ShowLogos = styled.div`
  width:100%;
  display:flex;
  float: left;
  padding: 0 80px 0 80px;
  margin: 30px 0;
  justify-content: space-around;
  flex-wrap: wrap;
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 147px;
      height: 36px;
    }
  }
  >div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 133px;
      height: 63px;
    }
  }
  >div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 193px;
      height: 48px;
    }
  }
  >div:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 119px;
      height: 49px;
    }
  }
  >div:nth-child(5){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 125px;
      height: 59px;
    }
  }
  @media(max-width: 1200px){
    padding: 0 10px;
    >div{
      width: auto;
      margin: 20px;
    }
  }
`;

const ButtonLearnMore = styled.button`
width: ${(props) => props.tryFree ? '200px' : '236px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#F6B479'};
  color: #253A5B;
  display: ${({ display }) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 3px;
  border: 2px solid #253A5B;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 500px) {
    width: 315px;
    margin-left: 2px;
  }
`;

const OverFooter = styled.div`
  >div:first-child{
    >p{
      margin: 0 0 10px;
      text-align: center;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      text-transform: capitalize;
      color: #24395a;
    }
  }
  >div{
    >p{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #3A3A3A;
      font-family: ${MontserratRegular};
    }
  }
  >div:last-child{
    display:flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 50px;
    >Button{
      width: 397px;
      margin-top:0;
      height: 45px;
      box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
    }
    >input{
      width: 397px;   
      padding-left:18px;
      height: 45px;
      border: 1px solid #C4C4C4;
      border-radius: 3px;
      font-size: 20px;
      margin-right: 5px;
      line-height: 23px;
      color: #C4C4C4;
      font-family: ${MediumFont}
    }
  }
  @media (max-width:720px){
    text-align: center;
    >div:nth-child(2){
      padding: 0 50px;
    }
    >div:last-child{
      display: block;
      text-align:center;
      >input{
        width: 80%;
        text-align: center;
      }
      >button{
        width: 80%;
        margin-top: 10px;
      }
    }  
  }
  @media(max-width: 500px){
    >div:nth-child(2){
      >p{
        font-size: 15px;
        line-height: 18px;
      }
    }
    >div:last-child{
      >button:last-child{
        font-size: 17px;
      }
    }
  }
`;

const Divider = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  padding: 100px 0;
  >img{

  }
`;

const ShowLogosDivider = styled.div`
  width:100%;
  margin-bottom: 40px;
  img{
    width:100%;
  }
`;
const PaddingContainer = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding ? '50px 117px 0px 117px' : '0px 117px 0px 117px'};
  float: left;
  @media (min-width: 1400px){
    padding: ${({ padding }) => padding ? '50px 117px 0px 155px' : '0px 117px 50px 155px'};
  }
  @media (max-width: 1100px) {
    padding: 50px 6vw;
  }
  @media (max-width: 500px) {
    padding: 0;
  }
`;

const RatingWrapper = styled.div`
  width:100%;
  padding : 50px 117px 0px 117px;
  @media(max-width:1000px){
    padding: 0;
  }
  @media(max-width: 700px){
    padding-top: 60px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 70px;
  text-align: center;

  >p{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #F6797D;
  }
  >h1{
    font-family: Rubik-Medium;
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #253A5B;
  }
  >img{
    width: 75px;
    height: 5px;
  }
  @media(max-width: 500px){
    display:none;
  }
`;

const RatingsContainers = styled(RatingsContainer)`
    .ratingStars {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 80px 0 10px 0;
    }
    .ratingDescription{
      padding: 0px 50px 5px;
      margin: 13px 13px 0px 13px;
      height: 150px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #3a3a3a;
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: ${MontserratRegular};
    }

    .ratingBy{
      span{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #3a3a3a;
      }
    }
    @media(max-width: 1000px){
      >img{
        left: 43%;
      }
      .ratingDescription{
        font-size: 16px;
      }
    }

    @media(max-width: 650px){
      .ratingDescription{
        line-height: 26px;
        text-align: justify;
        margin: 30px 0;
        padding: 20px 0 0;
      }
      >img{
        left: 40%;
      }
    }
    @media(max-width: 500px){
      .ratingDescription{
        font-size: 13px;
      }
      >img{
        left: 36%;
      }
    }
`;

const ResponsiveTestimonialTab = styled.div`
  display:none;
  @media(max-width: 1000px){
    float: left;
    width: 100%;
    display: flex;
    .leftArrow{
      cursor: pointer;  
      width: 30%;
      justify-content: center;
      display: flex;
      >img{
        margin: auto 0;
      }
      @media(max-width: 500px){
        justify-content: flex-start;
        padding-left: 20px;
      }
    }
    >div:nth-child(2){
      width: 40%;
      text-align: center;
      border-top: 5px solid #F6797D;
      padding: 10px;
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #F6797D;
      }
    }
    >.rightArrow{
      cursor: pointer;
      width: 30%;
      justify-content: center;
      display: flex;
      >img{
        margin: auto 0;
      }
      @media(max-width: 500px){
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
  }
`;

const TabsContainerTestimonial = styled(TabsContainer)`
  @media(max-width: 1000px){
    display:none;
  }
`;

const MenuListV2Main = styled(MenuList)`
  display: flex;
  width: 100%;
  @media (max-width: 1200px) {
    position: unset;
    width: 100%;
    box-shadow: none;
    background-color: none;
    padding: 0 0 10px;
  }
`;

const CustomListItemV2Main = styled(CustomListItem)`
  display: flex;
  width: 18%;
  justify-content: center;
  margin: 0;
  border-top: ${({ active }) => active ? '5px solid #F6797D' : '5px solid transparent'};
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-right: 40px;
  >a{
    font-size: 20px;
    font-family: Rubik-Medium;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
  }
  &:hover, &:focus, &:active {
    border-top: 5px solid #F6797D;
    border-bottom: none;
    >a{
      font-size: 20px;
    }
  }
  @media (max-width: 1200px) {
    min-width: unset;
    border-left: none;
    >a{
      font-size: 16px;
    }
  }
`;

const RowResponsiveWrapper = styled.div`
  display: block;
  width: 100%;
  padding: ${({ selected }) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({ selected, bgColor }) => selected ? bgColor : '#fff'};
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 35px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    >div{
      width: calc(100% - 70px);
      font-size: 24px;
      font-family: ${({ customColor }) => customColor ? `${BoldFont}` : `${MediumFont}`};
      color: #24385A;
    }
    .downArrow{
      width: 20px;
      transform: ${({ selected }) => selected && 'rotateX(180deg)'};
      >img{
        width: 14px;
        cursor: pointer;
      }
    }
  }
  .expand{
    padding: 20px 30px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    font-family: ${MontserratRegular};
    cursor: pointer;
  }
  @media(max-width: 500px){
    display: flex;
  }
`;

const GlobalMainWrapper = styled.div`
  width:100%;
  margin-top: 100px;
  display:flex;
  flex-direction: column;
  >div:first-child{
    width:100%;
    text-align:center;
    >p{
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #69C2FF;
      font-style: normal;
      font-weight: 600;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: ${ExtraBoldFont};
    }
  }
  >div:last-child{
    display: flex;
    flex-direction : row;
    padding: 80px 80px 80px 190px;
    >div{
      width:18%;
      text-align: center;
      >img{
        width: 140px;
        height: 158px;
      }
    }
  }
  @media(max-width: 1000px){
    >div:last-child{
      display: flex;
      flex-wrap: wrap;
      padding: 0 40px;
      justify-content: center;
      >div{
        margin: 40px 15px;
        width: auto
      }
    }
  }
  @media(max-width: 500px){
    padding: 0px 8px;
    margin-top: 60px;
    >div:first-child{
      >p{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.08em;
      }
      >h1{
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 0.03em;
      }
    }
    >div:last-child{
      padding: 0;
      >div{
        font-size: 15px;
        width: 102px;
        height: 115px;
        margin: 20px 7px;
        >img{
          width: 100%;
          height: 100%;
        }
      }

    }
  }
`;
const CoreWrapper = styled.div`
  width: 100%;
  margin: 100px 0;
  max-width: 1200px;
  margin: auto;
  margin-top: 0px;
  display:flex;
  flex-direction:column;
  .change {
    padding-left: 235px;
  }
  .tab-header{
    text-align:center;
    margin-bottom: 30px;
    >p{
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      color: rgb(105, 194, 255);
      font-family: ${MontserratSemiBold}
    }
    >h1{
      font-family: ${ExtraBoldFont};
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
  }
 
  .card-container{
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-start;

    .card-data{
      width: 300px;
      height: 100px;
      margin:  30px 0px 25px 0px;
      border-radius: 6px;
      // @media(max-width: 500px){
      //   width: 100%;
      //   margin:  15px 30px 15px 30px;
      // }
      // @media(max-width: 720px){
      //   width: 100%;
      //   margin:  15px 30px 15px 30px;
      // }
      // @media(max-width: 1000px){
      //   width: 100%;
      //   margin:  15px 30px 15px 30px;
      // }
      .image-container{
        width: auto;
        height: 50px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom:15px;
        margin-top:25px;
        >img{
          width: 50px;
          height: 50px;
          height:auto;
          max-height: 250px;
        }
      }
      .card-title{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        >h3{
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
       
      }
      .card-details{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 25px 27px 25px;
        text-align: center;
        >h3{
          font-family: 'Montserrat-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 19px;
          color: #9c9c9c;
          margin: 0px;
        }
       
      }

    }
  }

  .card-button{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    margin-top:55px;
    margin-bottom: 150px;
    >button{
      border: none;
      padding: 0px;
     
      .show-button{
      display: flex;
      align-items: center;
      width: 322px;
      height: 50px;
      background: #F6797D;
      box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      font-family: Rubik;
      font-style: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      text-transform: uppercase;
      border: none;
      .head-text{
        margin-left:25px;
        margin-top:13px;
        margin-bottom:13px;
        text-align: initial;
      }
      .show-button-text{
        width: 74%;
        text-align: left;
        padding: 20px;
        font-family: 'Rubik-Medium';
      }
      div{
        >img{
        height: 20px;
        width: 11px;
        position: relative;
        left: 57px;
      }
      }
      >img{
        height: 20px;
        width: 11px;
        position: relative;
        left: 57px;
      }
     }


      .hide-button{
        display:flex;
        align-items:center;
        width: 322px;
        height: 50px;
        border: 1px solid #F6797D;
        box-sizing: border-box;
        filter: drop-shadow(6px 4px 4px rgba(0, 0, 0, 0.08));
        border-radius: 6px;
        font-family: Rubik;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #F6797D;
        text-transform: uppercase;
        background-color: white;
        .hide-button-text{
          width: 74%;
          text-align: left;
          padding: 20px;
          font-family: 'Rubik-Medium';
        }
        div{
          >img{
          height: 11px;
          width: 20px;
          position: relative;
          left: 56px;
        }

        }
        >img{
          height: 11px;
          width: 20px;
          position: relative;
          left: 56px;
        }
              }
//       height: 50px;
//       width: 250px;
//       white-space: nowrap;
//       border: none;
//       -webkit-letter-spacing: 1px;
//       -moz-letter-spacing: 1px;
//       -ms-letter-spacing: 1px;
//       letter-spacing: 1px;
//       display: inline-block;
//       -webkit-align-items: center;
//       -webkit-box-align: center;
//       -ms-flex-align: center;
//       align-items: center;
//       -webkit-box-pack: center;
//       -webkit-justify-content: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//       font-family: Rubik;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 22px;
//       line-height: 26px;
//       color: #FD7175;
//       cursor: pointer;
//       background-color: rgba(253, 113, 117, 0.1);
//       border-radius: 6px;
    
// width: 322px;
// height: 50px;
// background: #F6797D;
// box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.08);
// border-radius: 3px;
// font-family: Rubik;
// font-style: normal;
// font-weight: 700;
// font-size: 18px;
// line-height: 21px;
// text-align: center;
// color: #FFFFFF;
// text-transform: uppercase;

>div{
  >img{
    height: 20px;
    width: 11px;
    position: relative;
    left: 57px;
  }
}
      // &:hover{
      //   background-color: #FD7175;
      //   color: #FFFFFF;
      // }
    }   
        }

        @media(max-width: 1200px){
          width: 100%;
          margin: 100px 0;
          max-width: 1200px;
          margin: auto;
          // margin-top: 150px;
          .change {
            padding-left: 235px;
          }
          .tab-header{
            text-align:center;
            margin-bottom: 30px;
            >p{
              font-style: normal;
              font-size: 24px;
              line-height: 29px;
              text-align: center;
              letter-spacing: 0;
              color: rgb(105, 194, 255);
              font-family: ${MontserratSemiBold}
            }
            >h1{
              font-family: ${ExtraBoldFont};
              font-size: 30px;
              line-height: 54px;
              text-align: center;
              letter-spacing: 0.03em;
              text-transform: capitalize;
              color: #24395A;
            }
          }
         
          .card-container{
             width: 100%;
             display: flex;
             flex-wrap: wrap;
             justify-content: center;
            .card-data{
              width: 300px;
              height: 100px;
              margin:  50px 0px 0px 0px;
              border-radius: 6px;
              // @media(max-width: 500px){
              //   width: 100%;
              //   margin:  15px 30px 15px 30px;
              // }
              // @media(max-width: 720px){
              //   width: 100%;
              //   margin:  15px 30px 15px 30px;
              // }
              // @media(max-width: 1000px){
              //   width: 100%;
              //   margin:  15px 30px 15px 30px;
              // }
              .image-container{
                width: auto;
                height: 50px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom:15px;
                margin-top:25px;
                >img{
                  width: 50px;
                  height: 50px;
                  height:auto;
                  max-height: 250px;
                }
              }
              .card-title{
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                flex-direction: column;
                >h3{
                  font-family: 'Rubik';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 26px;
                  letter-spacing: -0.025em;
                  color: #24395A;
                  margin: 0px;
                }
               
              }
              .card-details{
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                flex-direction: column;
                padding: 0px 25px 27px 25px;
                text-align: center;
                >h3{
                  font-family: 'Montserrat-Regular';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 19px;
                  color: #9c9c9c;
                  margin: 0px;
                }
               
              }
        
            }
          }
          .card-button{
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex-direction: column;
            margin-top:70px;
            margin-bottom: 50px;
            margin-left: 805px;
            @media (max-width: 1200px){
              margin-left: 0px;
            }
            >button{
              border: none;
             .show-button{
              width: 322px;
              height: 50px;
              background: #F6797D;
              box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.08);
              border-radius: 3px;
              font-family: Rubik;
              font-style: normal;
              font-size: 18px;
              line-height: 21px;
              text-align: center;
              color: #FFFFFF;
              text-transform: uppercase;
              border: none;
              >img{
                height: 20px;
                width: 11px;
                position: relative;
                left: 57px;
              }
             }
        
        
              .hide-button{
                width: 322px;
                height: 50px;
                border: 1px solid #F6797D;
                box-sizing: border-box;
                filter: drop-shadow(6px 4px 4px rgba(0, 0, 0, 0.08));
                border-radius: 3px;
                font-family: Rubik;
                font-style: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #F6797D;
        text-transform: uppercase;
        background-color: white;
        >img{
          height: 11px;
          width: 20px;
          position: relative;
          left: 57px;
        }
              }
        >div{
          >img{
            height: 20px;
            width: 11px;
            position: relative;
            left: 57px;
          }
        }
              &:hover{
                background-color: #FD7175;
                color: #FFFFFF;
              }
            }   
                }
        }

        .enterprise-card-wrapper{
          width:100%;
          display:flex;
          justify-content:center;
          margin-top: 75px;
          max-width: 1080px;
          margin-left: 60px;
          @media (max-width: 500px) {
            // padding: 16px;
            display: none;
          }
      }
          .enterprise-card{
            width:100%
            height: 200px;
            background: #FFFFFF;
            border: 2px solid #253A5B;
            border-radius: 6px;
            padding: 30px 50px 30px 30px;
            display:flex;

            &:hover {
              background: linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%);
              box-shadow: 0px 35px 31px rgba(0, 0, 0, 0.12);
              border:none;
              .enterprise-content{
                .card-content{
                  .upper-text{
                    color: white;
                  }
                  .lower-text{
                    color: white;
                  }
                }
              }
              .card-btns{
                .custom-text{
                  color: white;
                }
              .sales-btn{ 
                .contact-button{           
                  color: #24395A;
                  background-color: white;
                  border: none;
                }
                }
              }
            }
          .enterprise-pic{
            width: 140px;
            height: 140px; 
          }
          .enterprise-content{
            width:100%;
            padding: 6px 0px;
            display:flex;
            justify-content:space-between;
            .card-content{
              margin-left: 25px;
              .upper-text{
                width: 361px;
                height: 60px;
                left: 375px;
                top: 1053px;     
                font-family: 'Rubik-Medium';
                font-size: 24px;
                line-height: 30px;                            
                display: flex;
                align-items: center;
                text-transform: capitalize;               
                color: #253A5B;
                margin-bottom:15px;             
              }
              .lower-text{
                width: 402px;
                height: 48px;
                left: 375px;
                top: 1128px;
                font-family: 'Rubik-Regular';
                font-size: 18px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-transform: lowercase;
                color: rgba(37, 58, 91, 0.6); 
              }
            }           
          }
          .card-btns{
            display:flex;
            flex-direction:column;
            justify-content:center;
            .sales-btn{
              margin-bottom:15px
              .contact-button{
                background: #253A5B;
                width: 200px;
                height: 50px;
                font-family: 'Rubik-Medium';
                font-size: 18px;
                line-height: 24px;
                color:#FFFFFF;
                border-radius:3px;
              }
            }
            .custom-text{             
              font-family: 'Rubik';
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;             
              text-align: center;             
              color: #9C9C9C;             
            }
          }
          }
        }


// ----------------------------------- For mobile-view


        .enterprise-card-wrapper-mob{
          width:100%;
          display:flex;
          justify-content:center;
          margin-top: 75px;
          max-width: 1080px;
          // margin-left: 60px;
          @media (max-width: 500px) {
            padding: 16px;
          }
          @media (max-width: 1280px) {
            padding: 16px;
            margin-top: 0px;
          }
          @media (min-width: 1280px){
            display: none;
          } 

      }
          .enterprise-card-mob{
            width:100%
            height: 200px;
            background: #FFFFFF;
            border: 2px solid #253A5B;
            border-radius: 6px;
            padding: 30px 50px 30px 30px;
            display:flex;
            
            @media (max-width: 500px) {
              width: 100%;
              height: 465px;
              background: #FFFFFF;
              border: 2px solid #24395A;
              border-radius: 6px;
              // padding: 0px 0px 0px 0px;
              padding: 25px;
            }
            @media (max-width: 1280px) {
              width: 100%;
              height: 465px;
              background: #FFFFFF;
              border: 2px solid #24395A;
              border-radius: 6px;
              // padding: 0px 0px 0px 0px;
              padding: 25px;
            }

            &:hover {
              background: linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%);
              box-shadow: 0px 35px 31px rgba(0, 0, 0, 0.12);
              border:none;
              .enterprise-content-mob{
                .card-content-mob{
                  .upper-text-mob{
                    color: white;
                  }
                  .lower-text-mob{
                    color: white;
                  }
                }
              }
              .card-btns-mob{
                .custom-text-mob{
                  color: white;
                }
              .sales-btn-mob{ 
                .contact-button-mob{           
                  color: #24395A;
                  background-color: white;
                  border: none;
                }
                }
              }
            }
          .enterprise-pic-mob{
            width: 140px;
            height: 140px;
            @media (max-width: 500px){
              align: center;
              // margin: 0px 102px 15px 101px;
              margin: auto;
            }  
            @media (max-width: 1280px){
              align: center;
              // margin: 0px 102px 15px 101px;
              margin: auto;
            }    
          }
          .enterprise-content-mob{
            width:100%;
            padding: 0px 0px;
            display:flex;
            justify-content:space-between;
            @media (max-width: 1280px){
              display: flex;
              justify-content: center;
            }
            .card-content-mob{
              // margin-left: 25px;
              .upper-text-mob{
                width: 361px;
                height: 60px;
                left: 375px;
                top: 1053px;     
                font-family: 'Rubik-Medium';
                font-size: 24px;
                line-height: 30px;                            
                display: flex;
                align-items: center;
                text-transform: capitalize;               
                color: #253A5B;
                margin-bottom:15px; 
                @media (max-width: 500px){
                  width: 100%;
                  height: 90px;
                  margin: 0px 0px 15px 0px;
                  // padding: 10%;
                  text-align: center;
                }   
                @media (max-width: 1280px){
                  width: 100%;
                  height: 90px;
                  margin: 0px 0px 15px 0px;
                  // padding: 10%;
                  text-align: center;
                }                   
              }
              .lower-text-mob{
                width: 402px;
                height: 48px;
                left: 375px;
                top: 1128px;
                font-family: 'Rubik-Regular';
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-transform: lowercase;
                color: rgba(37, 58, 91, 0.6);
                @media (max-width: 500px){
                  width: 100%;
                  height: 60px;
                  // margin-top: 15px;
                  margin-top: 15px;
                  margin-bottom: 15px;
                  // padding: 5%;
                  text-align: center;
                }   
                @media (max-width: 1280px){
                  width: 100%;
                  height: 60px;
                  // margin-top: 15px;
                  margin-top: 15px;
                  margin-bottom: 15px;
                  // padding: 5%;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                }   
              }
            }           
          }
          .card-btns-mob{
            display:flex;
            flex-direction:column;
            justify-content:center;
            // @media (max-width: 500px){
            //   width: 100%;
            //   height: 50px;
            //   margin: 0px 72px 10px 71px;
            // }   
            .sales-btn-mob{
              margin-bottom:15px
              @media (max-width: 500px){
                width: 100%;
                height: 50px;
                // margin: 0px 0px 10px 45px;
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
              }   
              @media (max-width: 1280px){
                width: 100%;
                height: 50px;
                // margin: 0px 0px 10px 45px;
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
              }   
              .contact-button-mob{
                background: #253A5B;
                width: 200px;
                height: 50px;
                font-family: 'Rubik-Medium';
                font-size: 18px;
                line-height: 24px;
                color:#FFFFFF;
                border-radius:3px;
              }
            }
            .custom-text-mob{             
              font-family: 'Rubik';
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;             
              text-align: center;             
              color: #9C9C9C;             
            }
          }
          }
        }
`;

const CoreWrapperr = styled.div`
  width: 100%;
  margin: 100px 0;
  max-width: 1200px;
  margin: auto;
  margin-top: 75px;
  border-top: 1px solid #EDEDED
  @media (max-width: 1000px){
    margin-top: 25px;
  }
  .change {
    padding-left: 235px;
  }
  .tab-header{
    margin-top: 75px;
    text-align:center;
    margin-bottom: 50px;
    @media (max-width: 800px){
      margin-bottom: 0px;
      margin-top: 0px;
    }
    >p{
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      color: rgb(105, 194, 255);
      font-family: ${MontserratSemiBold}
    }
    >h1{
      font-family: ${ExtraBoldFont};
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    >h2{
      font-family: ${ExtraBoldFont};
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
  }
 .align{
   display: flex;
 }

 @media(max-width: 1200px) {
  .align{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 10px;
  }
}

`;


const CoreWrapperrNew = styled.div`


width: 100%;
margin: 100px 0;
max-width: 1200px;
margin: auto;
margin-top: 50px;
@media (max-width: 500px){
  margin-top: 0px;
  // margin-bottom: 25px;
}

.change {
  padding-left: 235px;
}
.tab-header{
  text-align:center;
  margin-bottom: 50px;
  >p{
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0;
    color: rgb(105, 194, 255);
    font-family: ${MontserratSemiBold}
  }
  >h1{
    font-family: ${ExtraBoldFont};
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #24395A;
  }
}
.align{
  display: flex;
}
@media(max-width: 1200px) {
  .align{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 10px;
  }
}

`;



const CustomList = styled(CustomListItem)`
    width: 25%;
    display: flex;
    justify-content: center;
    color: #69c2ff;
    border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
    >a{
      font-family : 'Rubik-Medium';
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      font-weight: normal;
      color: ${({ active }) => active ? '#69c2ff' : "#9C9C9C"};
    }
    &:hover, &:focus, &:active {
      color: #69c2ff;
      border-bottom: 5px solid #69c2ff;
      > a {
        color: #69c2ff;
      }
    }
    @media(max-width: 1200px) {
      border-left: none;
      border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
      &:hover, &:focus, &:active {
        border-left: none;
        border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
      }
    }
    ul > li:hover {
      color: #69c2ff;
    }
`;
const TabsContainerV2 = styled(TabsContainer)`
  padding: 0 0px 0 0px;
  @media(max-width: 720px){
    display:none;
  }
`;
const  ButtonLearnMoree = styled.button`
width: ${(props) => props.tryFree ? '190px' : '190px'};
height: 45px;
background-color: ${(props) => props.tryFree ? '#fff' : 'rgb(246, 180, 121)' };
color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
display: ${({display}) => display}; 
padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
border-radius: 3px;
border: none;
font-size: 16px;
line-height: 24px;
font-family: Rubik-Medium;
font-style: normal;
box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};

&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}

@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
}

@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 16px;
  padding: 10px 10px;
}
`;
const WrapperContainer = styled.div`
  width: 100%;
  
  margin: auto;
    background-repeat: no-repeat;
    background-size: 100% 400px;
    position: relative;
  @media(max-width: 500px){
    // padding-top: 30px;
    padding-top: 0px;
    // background-color: #F5FBFF;
  }
`;
const  IntroTextContainer = styled.div`
  display: inline-block;
  float: center;
  width: 100%;
  letter-spacing: 1.5px;
  padding: 0px 0px 0px 0px;
 
  h1 {    
    font-family: 'Rubik-Bold';
    font-size: 48px;
    line-height: 110.5%;
    text-align: center;
    text-transform: uppercase;
    color: #24395A;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  p {
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #F6797D;
    margin-bottom:25px;
    
  }
  .wrap {
    
  }
  .divider{
    width: 75px;
    >img{
      width: 100%;
      height: 5px;
    }
  }
  @media (max-width: 1201px) {
    width: 100%;
    // padding: 0px 10px 30px 80px; 
    
    h1{
      
      font-family: ${BoldFont};
      letter-spacing: 0;
      margin-top: 0px;
      font-weight: 700;
      text-transform: uppercase;
      // color: #FFFFFF;
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      text-align: center;
      text-tranform: uppercase;
    }
    p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    // margin: 30px 0 30px 0;
    // color: rgb(36, 57, 90);
    font-size: 16px;
    line-height: 31px;
    @media (max-width: 1201px){
      font-family: Rubik-Medium;
      // margin-top: 5%;
    }
    }
    .wrap {
      width: 60%;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    .wrap {
      width: 45%;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    text-align: left;
    >div:last-child{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media(max-width: 350px){
    >div:last-child{
      >button{
        width: 80%;
      }
    }
  }
  @media (max-width: 600px) {
      padding-top: 10px;
      height: 450px;
      .wrap {
        width: 100%;
      }
  }
  @media(max-width: 500px){
    padding: 10px;
    width: 100%;
    max-width: 750px;
    min-width: 350px;
    margin-left: 600px;
    height: auto;
    background-color: #F5FBFF;

    >h1{
      font-size: 30px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: bold;
      line-height: 110.5%;
      // margin-left: 20px;
      margin-bottom: 30px;
    }
    .divider{
      margin-left: 20px;
    }
    >p{
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      // margin-left: 20px;
      padding-right: 28px;
      text-align: justify;

font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
text-align: center;
padding-right: 16%;
padding-left: 16%;

    }
  }
  @media (max-width: 400px) {
    >h1{
      font-size: 28px;
    }

    .wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media (max-width: 360px) {
    >h1{
      font-size: 27px;
    }
  
`;

const AddOnContainer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0px 0px;
  background-color: #F5FBFF
   > div {
     margin-bottom: 40px;
    > h1 {
        font-size: 40px;
        color: #404555;
        font-family: Rubik-Medium;
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 60px;
        font-weight: 700;
      }
    > p {
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
  
      
      text-align: center;
      
      color: #69C2FF;
     }
   }
   .web-cards{
     display: flex;
     flex-direction: row;
     width: 100%;
     max-width: 1140px;
     
    //  margin-right: auto;
     margin-bottom: auto;
    //  margin-left: auto;
    @media (max-width: 500px){
      display: none;
    }
   }
   .cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1140px;
    
   //  margin-right: auto;
    margin-bottom: auto;
   //  margin-left: auto;
   @media (max-width: 4500px){
    display: none;
  }
  }
   @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
    > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;
    }
   }
`;

const AddOnCard = styled.div`
  float: left;
  margin: 0px 10px;
  
  width: 400px;
  border-radius: 4px;
  background-color: #fff;
  max-width: 500px;
  padding: 25px 0px 0px;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border: 2px solid #24395A;
  box-sizing: border-box;
  border-radius: 6px;
  // background: ${(active) => active ? '#fff' : 'linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%)'}
  &:hover {
    background: linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%);
    box-shadow: 0px 35px 31px rgba(0, 0, 0, 0.12);
    border:none;
    > div {
      > h1 {
        color: white;
      }
      > p{
        color: white;
      }
    >div{  
      > h3{
        color: white;
      }
      >h6{
        >sub{
          color:white;
        }
      }
    }
      > span {
        color: #24395A;
      }
    }
    .button {
      > div{
        color: #24395A;
        background-color: white;
      }
    }
    .point-wrap{
      .tick-img{
        // background: url("/images/tickimagewhite.svg") ;
        display: none;
      }
      .tick-img-white{
        width:20px;
        height:20px;
        display: flex;
        position: absolute;
        z-index: 99;
      }
      .subpoint1{
        color: white;
      }
    }
  }
  > div {
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    > img {
      width: auto;
      height: 88px;
    }
    > h1 {
      font-family: ${BoldFont};
      font-size: 24px;
      font-weight: 500;
      line-height: 80%;
      color: #404555;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 80%;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      margin-bottom:25px;
      margin-top: 0px;
    }
    > h2 {
      font-family: ${BoldFont};
      font-size: 24px;
      font-weight: 500;
      line-height: 80%;
      color: #404555;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 80%;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      margin-bottom:25px;
      margin-top: 0px;
    }
    > p {
      font-family: Rubik;
      font-size: 14px;
      line-height: 25px;
      color: #3f4557;
      letter-spacing: 0;
      font-weight: 400;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #9C9C9C;
      margin-bottom: 0px;
      // margin-top: 10px;
      text-transform: none;
    }
    .descmain{
      width: 170px;
      height: 20px;    
      background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
      // opacity: 0.3;
      border-radius: 3px;
      margin-left: 85px;
      margin-top: 10px;
    .desc-letter{
      margin-top:0px;
      width: 150px;
      height: 16px;     
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;     
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: lowercase;     
      color: #24395A; 
      margin-left:10px    
    }
  }
  .rupee-section{
    display: flex;
    // margin-left: 118px;
    justify-content: center;
    >h3{
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    >h6{
      margin-top: auto;
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 35px;
      // line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 28px;
      margin-bottom: 10px;
    }
  }
    >span{
      font-family: Rubik-Medium;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #F6797D;
      display: inline-block;
    }
  }
  .button {
    width: 100%;
    display: flex;
    align-items: center;
    
    > div {
      width: 220px;
      height: 50px;
      padding-top: 17px;
      background-color: #3A74A7;
      color: #fff;  
      font-size: 18px;
      cursor: pointer;
      font-family:'Rubik-Medium';
      background: #24395A;
      border-radius: 3px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
  .to-seemore{
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    >button{
      border: none;
      background: none;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;   
      text-transform: lowercase; 
      color: rgba(36, 57, 90, 0.6);
    }
  }
  .hr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  >div{
    // @media (max-width: 500px){
    //   display:  none;
    // }
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      display: none;
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      margin-left: 30px;
      margin-bottom: 0px;
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 0px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    min-height: 417px; 
    margin-bottom: 20px
  }

// for mobile view ---------------------------------------------------------------------------------

  .hrr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 20px;
    margin-bottom: 25px;
    // @media (max-width: 500px){
    //   display: none;
    // }
  }

  .display-points{
    // @media (max-width: 500px){
    //   display:  none;
    // }
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      display: none;
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      margin-left: 30px;
      margin-bottom: 0px;
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 0px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    // min-height: 417px; 
    min-height: 360px;
    margin-bottom: 20px
  }
`;


const AddOnCardMobile = styled.div`
  float: left;
  margin: 0px 10px;
  
  width: 400px;
  border-radius: 4px;
  background-color: #fff;
  max-width: 500px;
  padding: 25px 0px 0px;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border: 2px solid #24395A;
  box-sizing: border-box;
  border-radius: 6px;
  // background: ${(active) => active ? active : 'linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%)'};
  
  
  // &:hover {
  //   background: linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%);
  //   box-shadow: 0px 35px 31px rgba(0, 0, 0, 0.12);
  //   border:none;
  //   > div {
  //     > h1 {
  //       color: white;
  //     }
  //     > p{
  //       color: white;
  //     }
  //   >div{  
  //     > h3{
  //       color: white;
  //     }
  //     >h6{
  //       >sub{
  //         color:white;
  //       }
  //     }
  //   }
  //     > span {
  //       color: #24395A;
  //     }
  //   }
  //   .button {
  //     > div{
  //       color: #24395A;
  //       background-color: white;
  //     }
  //   }
  //   .point-wrap{
  //     .tick-img{
  //       // background: url("/images/tickimagewhite.svg") ;
  //       display: none;
  //     }
  //     .tick-img-white{
  //       width:20px;
  //       height:20px;
  //       display: flex;
  //       position: absolute;
  //       z-index: 99;
  //     }
  //     .subpoint1{
  //       color: white;
  //     }
  //   }
  // }
  > div {
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    > img {
      width: auto;
      height: 88px;
    }
    > h1 {
      font-family: ${BoldFont};
      font-size: 24px;
      font-weight: 500;
      line-height: 80%;
      color: #404555;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 80%;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      margin-bottom:25px;
      margin-top: 0px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
    > h2 {
      font-family: ${BoldFont};
      font-size: 24px;
      font-weight: 500;
      line-height: 80%;
      color: #404555;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 80%;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      margin-bottom:25px;
      margin-top: 0px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
    > p {
      font-family: Rubik;
      font-size: 14px;
      line-height: 25px;
      color: #3f4557;
      letter-spacing: 0;
      font-weight: 400;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #9C9C9C;
      margin-bottom: 0px;
      // margin-top: 10px;
      text-transform: none;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#9C9C9C'};
      }
    }
    .descmain{
      width: 170px;
      height: 20px;    
      background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
      // opacity: 0.3;
      border-radius: 3px;
      margin-left: 85px;
      margin-top: 10px;
    .desc-letter{
      margin-top:0px;
      width: 150px;
      height: 16px;     
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;     
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: lowercase;     
      color: #24395A; 
      margin-left:10px    
    }
  }
  .rupee-section{
    display: flex;
    // margin-left: 118px;
    justify-content: center;
    >h3{
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 25px;
      margin-bottom: 10px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
    >h6{
      margin-top: auto;
      font-family: Rubik-Medium;
      font-weight: 500;
      font-size: 35px;
      // line-height: 48px;
      text-align: center;
      color: #24395A;
      margin-top: 28px;
      margin-bottom: 10px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#24395A'};
      }
    }
  }
    >span{
      font-family: Rubik-Medium;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #F6797D;
      display: inline-block;
    }
  }
  .button {
    width: 100%;
    display: flex;
    align-items: center;
    
    > div {
      width: 220px;
      height: 50px;
      padding-top: 17px;
      background-color: #3A74A7;
      color: #fff;  
      font-size: 18px;
      cursor: pointer;
      font-family:'Rubik-Medium';
      background: #24395A;
      border-radius: 3px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      // color: #FFFFFF;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#24395A' : '#fff'};
        background: ${({active}) => active ? '#fff' : '#24395A'}
      }
    }
  }
  .to-seemore{
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    >button{
      border: none;
      background: none;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;   
      text-transform: lowercase; 
      color: rgba(36, 57, 90, 0.6);
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : 'rgba(36, 57, 90, 0.6)'};
      }
    }
  }
  .hr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  >div{
    // @media (max-width: 500px){
    //   display:  none;
    // }
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      // display: none;
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      margin-left: 30px;
      margin-bottom: 0px;
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 0px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    min-height: 417px; 
    margin-bottom: 20px;
  }

// for mobile view ---------------------------------------------------------------------------------

  .hrr-line{
    width: 310px;
    height: 0px;
    left: 195px;
    top: 881px;
    // border: 0.5px solid rgba(156, 156, 156, 0.4);
    margin-top: 20px;
    margin-bottom: 25px;
    // @media (max-width: 500px){
    //   display: none;
    // }
  }

  .display-points{
    // @media (max-width: 500px){
    //   display:  none;
    // }
    .pointhead{
      width: 176px;
      height: 20px;
      left: 575px;
      top: 906px;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;           
      display: flex;
      align-items: center;     
      color: rgba(36, 57, 90, 0.6);
      margin-left: 25px;
      margin-bottom: 15px;
      white-space: nowrap;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#9C9C9C'};
      }
    }
    .point-wrap{
      display:flex;
      margin-left: 25px;
      margin-bottom: 15px;
    .tick-img{
      width:20px;
      height:20px;
      display: flex;
      position: absolute;
      z-index: 99;
    }
    .tick-img-white{
      // display: none;
      @media (max-width: 1000px){
        height: 20px;
      }
     }
    .subpoint1{      
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;     
      display: flex;
      align-items: center;      
      color: #24395A;
      text-transform: capitalize;
      // margin-left: 30px;
      margin-bottom: 0px;
      margin-left: 10px;
      @media (max-width: 1000px){
        color: ${({active}) => active ? '#fff' : '#fff'};
      }
    }
    .space{
      margin-bottom: 30px;
    }
  }
  }
  @media (max-width: 550px) {
    margin: 0px 0px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .priceContainer {
      margin-top: 20px;
    }  
  }
  @media (max-width: 1000px) {
    max-width: 95%;
    // min-height: 417px; 
    min-height: 360px;
    margin-bottom: 20px;
    background: ${({active}) => active ? 'linear-gradient(360deg, #00B1BC 0%, #75D9A3 100%)' : '#fff'};
  }
`;

const QuestionCard = styled.div`
  float: left;
  margin: 15px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(105, 194, 255, 0.5);
  background-color: ${({ selected, bgColor }) => selected ? bgColor : '#fff'};
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    padding: 27px 30px 28px 30px;
    .arrow{
      >i{
        color: #3A3A3A;
        font-size: xxx-large;
      }
    }
      .question-close{
        font-size: 24px;
        line-height: 120%;
        color: #3f4557;
        letter-spacing: 0;
        margin: 0px;
        text-align: left;
        font-family: 'Rubik';
      }
      .question-open{
        font-size: 24px;
        line-height: 120%;
        color: #3f4557;
        letter-spacing: 0;
        margin: 0px;
        text-align: left;
        font-family: 'Rubik-Medium';
      }
    a:hover {
      text-decoration: none;
    }
  }
  >span {
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    > i {
      color: #6E6E6E;
      font-size: xxx-large;
      }
    }
  }
  .accordion {
    width: 100%;
    padding-top: 0px;
    > p {
      font-family: ${MediumFont};
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      font-weight: 100;
      font-family: 'Montserrat-Regular';
      font-size: 18px;
      line-height: 120%;
      text-align: left;
      font-weight: 100;
      color: #3a3a3a;
    }
  }
  > div:first-child {
    cursor: pointer;
  }
  .rotateIcon {
    transform: rotate(180deg);
  }
`;

const QuestionContainer= styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 35px 0px;
  padding-bottom: 0px;
  margin-top: 15px;
  margin-bottom: 150px;
  strong {
    font-family: ${BoldFont};
  }
   > div {
    > h1 {
        font-size: 40px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        margin-top: 15px;
        line-height: 60px;
        font-weight: 700;
      }
    > p {
        font-family: ${RegularFont};
        font-size: 21px;
        line-height: 32px;
        color: #3f4557;
        letter-spacing: 0;
     }
   }
   .cards {
     display: flex;
     flex-direction: row;
     max-width: 1200px;
     margin: auto;
   }
   .questionContainer {
     display: flex;
     flex-direction: column;
     width: 1200px;
     margin: auto;
   }
   @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 50px;
    padding-top: 0px;
    > div {
      width: 100%;
    }
    > div:last-child {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;
    }
   }
   @media (max-width: 600px){
    .questionContainer{
      width: 100%;
    }
   }
`;

const TabData = styled.div`
  padding: 75px 0px 108px;
  width:100%;
  max-width: 1200px;
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #EDEDED
  .responsiveWhyButton{
    display:none;
    >button{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-left: 0;
      width: 315px;
    }
  }
  >div:nth-child(2){
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    img {
        width: 80%;
    }
  }
  > div:last-child{
    float: left;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    img {
      width: 13%;
      margin-left:16px;
      margin-bottom:10px;
    }
    > p:first-child{
        width:100%;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0;
        color:#69C2FF;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratSemiBold};
     }
    > p {
        width:100%;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: #24395a;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratRegular};
        @media(max-width: 500px){
          font-family: ${MontserratRegular};
          text-align: justify;
          padding-right: 12px;
          font-weight: 400;
          fon-size: 15px;
          font-style: normal;
          line-height: 18px;
          padding-left: 0px;
          padding-right: 0px;
          text-align: left;
        }
     }
    > h1 {
        font-size: 30px;
        margin-bottom: 25px;
        color: #24395a;
        font-family: Rubik-bold;
        font-style: normal;
        letter-spacing: 0;
        line-height: 36px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        text-transform: capitalize;
        margin-top: 0;
        @media(max-width: 500px){
          text-align: left;
          margin: 0;
        }
    } 
    > h2 {
        font-size: 30px;
        margin-bottom: 25px;
        color: #24395a;
        font-family: Rubik-bold;
        font-style: normal;
        letter-spacing: 0;
        line-height: 36px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        text-transform: capitalize;
        margin-top: 0;
        @media(max-width: 500px){
          text-align: left;
          margin: 0;
        }
    } 
  }
  @media(max-width:1000px){
    >div{
      >h1{
    font-size: 24px;
      }
    }
  }
  @media(max-width:850px){
    flex-direction: column-reverse;
    padding: 0px 20px 25px;
    margin: 0;
    >div:nth-child(2){
      margin-top: 26px;
      >img{
        margin: 0;
        width: 100%;
      }
    }
    >div:last-child{
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 60px;
      >p:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-family: 'Montserrat-Regular';
        line-height: 19px;
        margin-bottom: 26px;
        text-align: left;
        padding-left: 0px;
      }
      >h1{
        padding: 0;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: left;
      }
      >img{
        margin-left: 0;
        margin-top: 41px;
        margin-bottom: 41px;
        margin-right: auto;
        width: 78px;
      }
      >p:last-child{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        padding: 0;
      }
      >button{
        display: none;
      }
  }
  .responsiveWhyButton{
    display: block;
    margin: 0 auto;
    width: 94%;
  }
}

@media(max-width:500px){
  >div{
    >h1{
      font-size: 13px;
      line-height: 19px;
    }
    >p{
      font-size: 11px;
      line-height: 16px;
    }
    p:last-child{
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      padding: 0;
      text-align: justify;
    }
  }
  >div:last-child{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 25px;
  }
`;
const Button2 = styled.button`
width: ${(props) => props.tryFree ? '200px' : '190px'};
margin-top:20px;
  background-color: ${(props) => props.tryFree ? '#fff' : '#69C2FF'};
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff'};
  display: ${({ display }) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '12px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-style: normal;
  line-height: 21px;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;

const MainContent = styled.div`
  background-color: #F5FBFF;
  margin-left: -600px;
  margin-right: -600px;
  .card-button{
    margin-bottom: 75px;
    @media (max-width: 500px){
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      margin-left: 1200px;
   }
    @media (max-width: 1200px){
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          // margin-left: 1200px;
          margin-top: 50px;
          margin-bottom: 61px;
       }
  }
  @media (max-width: 500px){
     width: 100%;
     height: auto;
     display: flex;
     justify-content: center;
    //  margin-left: 0px;
  }
  @media (max-width: 1200px){
    // width: 100%;
    height: auto;
    // display: flex;
    justify-content: center;
    // margin-left: -600px;
 }
//  @media (min-width: 820px){
//   width: 100%;
//   height: auto;
//   display: flex;
//   justify-content: center;
//   margin-left: 0px;
//   margin-top: 157%;
// }
`;

const Section = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: block;
  font-family: ${RegularFont};
  display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  position: relative;
  padding-top: 100px;
  background-color: #F5FBFF;
  @media (max-width: 991px) {
    display: block;
  }
  @media (max-width: 1201px) {
    padding-top: 0px;
  }
  @media (max-width: 930px) {
    flex-flow: column;
    height: 1100px;
    padding-top: 30px;
  }
  @media(max-width: 700px){
    margin-bottom: 0;
    height: auto;
  }
`;

const TeamTabContainer = styled.div`
  width: ${(props) => props.setTabWidth ? '100%' : '100%'};
  background-color: #fff;
  display: flex;
  margin-top: 50px;
  margin-bottom: 71px;
    > div {
        float: left;
        padding: 10px 0px;
        font-family: ${BoldFont};
        font-size: 18px;
        color: #fff;
        cursor: pointer;
    }
    div:first-child {
    width: 49%;
    display: flex;
    margin: auto;
    @media (max-width: 600px){
      width: 100%
    }
    div:first-child {
      width: 420px;
      border-radious: 20px;
      height: 50px;
      background: #24395a;
      background-color: rgba(36, 57, 90, 0.04);
      border-radius: 30px;
      margin: 0px;
      @media (max-width: 500px){
        width: 100%;
      }
      div:first-child {
        background-color: ${(props) => props.active ? '#24395A' : 'rgba(36, 57, 90, 0.00)'};
        color: ${(props) => props.active ? '#fff' : '#24395A'};
        height: 50px;
        width: 210px;
        margin: 0px;
        padding-top: 15px;
        border-radius: 30px;
        font-family: Rubik;
        font-family: ${(props) => props.active ? 'Rubik-Medium' : 'Rubik'};
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-transform: capitalize;
        // padding-left: 50px;
        @media (max-width: 450px){
          padding-top: 0px;
        }

    }
    >div:nth-child(2){
        background-color: ${(props) => !props.active ? '#24395A' : 'rgba(36, 57, 90, 0.00)'};
        color: ${(props) => !props.active ? '#fff' : '#24395A'};
        height: 50px;
        width: 210px;
        margin: 0px;
        padding-top: 15px;
        border-radius: 30px;
        justify-content: flex-end;
        font-family: Rubik;
        font-family: ${(props) => props.active ? 'Rubik' : 'Rubik-Medium'};
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        text-transform: capitalize;
        padding-right: 35px;
        @media (max-width: 450px){
          padding-top: 0px;
        }

    }
  }
  }
  div:last-child{
        display: flex;
        width: 51%;
        height: 50px;
        margin: auto;
        @media (max-width: 600px){
          width: 100%
        }
        > div:first-child {
          width: 204px;
          height: 50px;
          color: #3F4555;
    font-family: Rubik-Medium;
    font-size: 24px;
    display: flex
    text-align: left;
    justify-content: center;
    // padding-right: 35px;
    @media (max-width: 450px){
      font-size: 16px;
      line-height: 24px;
      height: auto;
      align-items: center;
      margin-left: -15%;
    }
        }
        >div:nth-child(2){
          width: 204px;
          height: 50px;
          color: #3F4555;
    font-family: Rubik-Medium;
    font-size: 24px;
    display: flex
    text-align: left;
    justify-content: center;
    // padding-right: 15px;
    @media (max-width: 450px){
      font-size: 16px;
      line-height: 24px;
      height: auto;
      align-items: center;
    }
        }
        >div:nth-child(3){
          width: 204px;
          height: 50px;
          color: #3F4555;
    font-family: Rubik-Medium;
    font-size: 24px;
    display: flex
    text-align: left;
    justify-content: center;
    // padding-right: 15px;
    @media (max-width: 450px){
      font-size: 16px;
      line-height: 24px;
      height: auto;
      align-items: center;
      margin-right: -10%;
    }
        }
    }

    @media (max-width: 500px){
        > div {
            width: 100%;
        }
    }
  @media (max-width: 1000px) {
    width: 100%
  }
  @media (max-width: 600px) {
    padding: 0% 0%;
    display: flex;
    flex-direction: column;
    padding: 4%;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
const Table = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  color: #404555;
  padding-bottom: ${(props) => props.padding ? '0' : '0px'};
  margin-bottom: ${(marginBottom) => marginBottom ? marginBottom : ''};
  .tableRow {
    // float: left;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(105,194,255,0.1);
    margin-bottom: 10px;
    flex-wrap: wrap;

    .subcontent{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      background-color: white;
      padding-top: 0px;
      .subcontent-tableRow{
        background-color: white;
        margin-bottom: 0px;
        .subcontent-tableRow-feature-names{

        }
        .dot-set{
            display: flex;
          .dot-img{
            margin-left: 20px;
            margin-top: 8px;
          }
          .dot-letter{
            margin-left: 15px;
            margin-bottom: 0px;
          }
        }
      }
    }
    .dot-img{
      width: 8px;
      height: 8px;
      // left: calc(50% - 8px/2 - 584px);
      // top: calc(50% - 8px/2 - 2688px);
      
      background: rgba(156, 156, 156, 0.5);
    }
    .tick{
      padding-top: 15px;
    }
     > div {
        width: calc(18% - 20px);
        color: #31adf8;
        font-size: 18px;
        font-family: ${MediumFont};
        text-align: center;
        // padding: 15px 0px; 
        // background-color: rgba(105, 194, 255, 0.1);
        height: 60px;
        padding-top: 15px;
        // margin: 0px 0px 0px 20px
        // margin-bottom: 10px;
        // margin-left: 20px;
        .btn-main{
          border: none;
          background: none;
          .drop-btn{
            border: none;
            background: none;
            @media (max-width: 450px){
              >img{
                width: 20px;
                height: 15px;
              }
            }
          }
          .drop-btn-invisible{
            width: 35px;
          }
        }
      }
      > div:first-child {
        width: ${(props) => props.setWidth ? '83%' : '49%'};
        color: #3A3A3A;
        font-family: Montserrat-Medium;
        font-size: 20px;
        text-align: left;
        margin-left: 0px;
        padding-top: 15px;
      }
      img {
        width: 22px;
        height: 22px;
        @media (max-width: 500px){
          margin-bottom: 10px;
        }
      }
    .features {
      display: flex;
      align-items: center;
      padding-left: 15px !important;
      span:last-child {
        padding-left: 7px;
      }
    }
  }
  .evenRow {
    background-color: white;
    border-bottom: 2px solid #eaeaea;
    // margin-bottom: 10px;
  }
  .enterpriseRow {
    background-color: white;
  }
  .enterPriseRow {
    justify-content: space-between;
    > div:first-child {
        width: 65%;
    }
    > div:last-child {
        width: 35%;
    }
  }
  .packageHeading {
    padding-left: 40px;
    padding-bottom: 10px;
    font-family: ${BoldFont};
    font-size: 18px;
  }
  .packageHeadingEnterprise {
    color: #fff;
    padding: 20px 0 17px 40px;
    font-family: ${BoldFont};
    font-size: 18px;
  }
  @media (max-width: 600px){
    .tableRow {
       > div:first-child {
          padding-left: 10px;
       }
    }
  }
  @media (max-width: 400px){
    .tableRow {
       > div:first-child {
          font-size: 14px;
          padding: 0 0 0 5px;
       }
       img {
          width: 25px;
          height: 25px;
       }
    }
  }
  @media (max-width: 600px){
     .packageHeading {
          padding-left: 10px;
     }
  }
  @media (max-width: 600px){
     .packageHeadingEnterprise {
          padding: 20px 0 17px 11px;
     }
  }
  @media (max-width: 450px){
     .packageHeadingEnterprise {
        padding: 20px 0 17px 10px;
        font-size: 14px;
     }
  }
  @media (max-width: 450px){
    .tableRow {
      padding-top: 3%;
      padding-bottom: 3%
       > div {
          width: 20%;
          height: auto;
          padding: 0px;
          // margin-left: 9%;
          // margin-right: 4%;
          // padding-left: 9%;
          // padding-right: 4%;
       }
       > div:first-child {
          // width: 40%;
          width: 100%
          height: auto;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 4%;
       } 
    }
    .enterPriseRow {
      > div:first-child {
         width: 65%;
      }
      > div:last-child {
        width: 35%;
      }
  }
  }
  @media (max-width: 410px){
    .features {
       span:last-child {
        font-size: 14px;
      }
    }
  }
`;

const TableLast = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  color: #404555;
  padding-bottom: ${(props) => props.padding ? '0' : '15px'};
  margin-bottom: ${(marginBottom) => marginBottom ? marginBottom : ''};
  .tableRow {
    float: left;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
     > div {
        width: 17%;
        color: #31adf8;
        font-size: 18px;
        font-family: ${MediumFont};
        text-align: center;
        padding: 15px 0px; 
        background-color: rgba(105, 194, 255, 0.1);
        height: 60px;
        margin: 0px 0px 0px 20px
      }
      > div:first-child {
        width: ${(props) => props.setWidth ? '83%' : '49%'};
        color: #404555;
        font-family: ${MediumFont};
        font-size: 20px;
        text-align: left;
        background-color: white;
        margin-left: 0px;
      }
      img {
        width: 22px;
        height: 21px;
      }
    .features {
      display: flex;
      align-items: center;
      padding-left: 15px !important;
      span:last-child {
        padding-left: 7px;
      }
    }
  }
  .evenRow {
    background-color: white;
  }
  .enterpriseRow {
    background-color: white;
  }
  .enterPriseRow {
    justify-content: space-between;
    > div:first-child {
        width: 65%;
    }
    > div:last-child {
        width: 35%;
    }
  }
  .packageHeading {
    padding-left: 40px;
    padding-bottom: 10px;
    font-family: ${BoldFont};
    font-size: 18px;
  }
  .packageHeadingEnterprise {
    color: #fff;
    padding: 20px 0 17px 40px;
    font-family: ${BoldFont};
    font-size: 18px;
  }
  @media (max-width: 600px){
    .tableRow {
       > div:first-child {
          padding-left: 10px;
       }
    }
  }
  @media (max-width: 400px){
    .tableRow {
       > div:first-child {
          font-size: 14px;
          padding: 0 0 0 5px;
       }
       img {
          width: 13px;
          height: 13px;
       }
    }
  }
  @media (max-width: 600px){
     .packageHeading {
          padding-left: 10px;
     }
  }
  @media (max-width: 600px){
     .packageHeadingEnterprise {
          padding: 20px 0 17px 11px;
     }
  }
  @media (max-width: 450px){
     .packageHeadingEnterprise {
        padding: 20px 0 17px 10px;
        font-size: 14px;
     }
  }
  @media (max-width: 450px){
    .tableRow {
       > div {
          width: 20%;
       }
       > div:first-child {
          width: 40%;
          // width: 100%
       } 
    }
    .enterPriseRow {
      > div:first-child {
         width: 65%;
      }
      > div:last-child {
        width: 35%;
      }
  }
  }
  @media (max-width: 410px){
    .features {
       span:last-child {
        font-size: 14px;
      }
    }
  }
`;


const TableContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  background-color: #FFFFFF;
  margin-botttom: 150px;
    .tableHeader {
      float: left;
      width: 100%;
      height: 60px;
      background-color: #E8F2F6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 17%;
        color: #3F4556;
        font-size: 18px;
        font-family: ${RegularFont};
        text-align: center;
        margin-top: 4px;
        font-weight: 400;
        padding-right: 18px;
      }
      > div:first-child {
        width: 49%;
        color: #3F4555;
        font-family: ${BoldFont};
        font-size: 20px;
        padding-left: 40px;
        text-align: left;
        font-weight: 600;
        margin-bottom: 10px;
        background: white;
      }
    }
  .enterpriseHeader {
      > div: first-child {
          width: 50%;
          text-align: center;
          text-align: left;
          padding-left: 40px;
          color: #3F4555;
          font-family: ${BoldFont};
          font-size: 20px;
          font-weight: 600;
      }
      > div: last-child {
          width: 50%;
          text-align: center;
          text-align: left;
          padding-left: 40px;
          color: #3F4555;
          font-family: ${BoldFont};
          font-size: 20px;
          font-weight: 600;
      }
  }  
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 0px;
    .tableHeader {
      > div:first-child {
        padding-left: 10px;
      }
    }
  }
  @media (max-width: 450px) {
    .tableHeader {
      > div:first-child {
        width: 34%
        font-size: 16px;
      }
      > div {
        width: 22%;
        font-size: 16px;
      }
    }
  }
  @media (max-width: 450px) {
    .enterpriseHeader {
      > div:first-child {
        width: 50%
        font-size: 17px;
        padding-left: 10px;
      }
      > div:last-child {
        width: 50%
        font-size: 17px;
        padding-left: 10px;
      } 
    } 
  }  
`;

const TableHeader = styled.div`
  float: left;
  width: 100%;
  height: 60px;
  background-color: #E8F2F6;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .testt {
    width: 49%;
    color: #3F4555;
    font-family: ${BoldFont};
    font-size: 20px;
    padding-left: 40px;
    text-align: left;
    font-weight: 600;
  }
  .test{
    width: 17%;
    color: #3F4556;
    font-size: 18px;
    font-family: ${RegularFont};
    text-align: center;
    margin-top: 4px;
    font-weight: 400;
  }
}
`;

const Test = styled.div`
display: flex;
.card-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
 .card-data{
   width: 550px;
   height: 450px;
   margin:  15px 0px 15px 50px;
   border-radius: 6px;
   background: ${({bgColor}) => bgColor};
   @media (max-width: 1000px){
    margin:  0px 0px 0px 0px;
   }
  //  @media(max-width: 500px){
  //    width: 100%;
  //    margin:  15px 30px 15px 30px;
  //  }
  //  @media(max-width: 720px){
  //    width: 100%;
  //    margin:  15px 30px 15px 30px;
  //  }
  //  @media(max-width: 1000px){
  //    width: 100%;
  //    margin:  15px 30px 15px 30px;
  //  }
   .first-div{
     width: 100%;
     display: flex;
     padding-left: 25px;
     padding-right: 20px;
     margin-top: 25px;
 
     >img{
   
     }
     >h1{
       font-family: Rubik;
       font-style: normal;
       font-weight: 700;
       font-size: 30px;
       line-height: 80%;
       color: #24395A;
       padding-left: 15px;
     }
   }
   .second-div{
   width: 100%;
   margin-top: 25px;
   padding-left: 25px;
   padding-right: 25px;
   min-height: 83px;
     >h3{
       width: 100%;
       font-family: 'Montserrat-Regular';
       font-style: normal;
       font-weight: normal;
       font-size: 16px;
       line-height: 26px;
       text-align: justify;
       color: #24395A;
     }
    
   }
   .third-div{
     display: flex;
     flex-wrap: wrap;
     margin-top: 25px;
     justify-content: flex-start;
     padding-left: 25px;
     padding-right: 20px;
     border-bottom: 1px solid #ECECEC;
     padding-bottom: 25px;
     justify-content: space-between;
     >div{
       width: 153px;
       height: auto;
       display: flex;
       margin-top: 15px;
       >img{
       }
       >span{
         font-family: Rubik;
         font-style: normal;
         font-weight: normal;
         font-size: 18px;
         line-height: 22px;
         color: #3A3A3A;
         padding-left: 15px;
         margin: auto;
         margin-left: -6px;
       }
     }
   }
   .four-div{
     display: flex;
     margin-top: 25px;
     padding-left: 25px;
     padding-right: 25px;
     >div:first-child{
       width: 60%;
       display: flex;
       >h1{
         font-family: Rubik;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 80%;
text-align: center;
text-transform: uppercase;
color: #24395A;
margin: 0px;
padding-top: 10px;
       }
       >span{
         font-family: Montserrat-Regular;
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 62%;
         display: flex;
         align-items: center;
         text-transform: lowercase;
         color: rgba(36, 57, 90, 0.6);
         padding-left: 13px;
         padding-top: 10px;
       }
     }
     >div:last-child{
       width: 40%;
       display: flex;
       justify-content: flex-end;
       >button{
         width: 174px;
         height: 41px;
         left: 471px;
         top: 2492px;
         background: #24395A;
         border-radius: 3px;
         font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
       }
     }
   }
 }
}
.card-button{
 display: flex;
 -webkit-box-align: center;
 align-items: center;
 -webkit-box-pack: center;
 justify-content: center;
 flex-direction: column;
 margin-top:35px;
 >button{
   height: 50px;
   width: 250px;
   white-space: nowrap;
   border: none;
   -webkit-letter-spacing: 1px;
   -moz-letter-spacing: 1px;
   -ms-letter-spacing: 1px;
   letter-spacing: 1px;
   display: inline-block;
   -webkit-align-items: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   font-family: Rubik;
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   color: #FD7175;
   cursor: pointer;
   background-color: rgba(253, 113, 117, 0.1);
   border-radius: 3px;
   margin: 0px;
   &:hover{
     background-color: #FD7175;
     color: #FFFFFF;
   }
 }   
     }

     @media(max-width: 1200px){
      display: flex;
      width: 100%;
      .card-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
       .card-data{
         width: 100%;
         height: auto;
         margin:  50px 0px 0px 0px;
         border-radius: 6px;
         background: ${({bgColor}) => bgColor};
         padding-bottom: 25px;
         .first-div{
           width: 100%;
           display: flex;
           padding-left: 25px;
           padding-right: 20px;
           margin-top: 25px;
       
           >img{
         
           }
           >h1{
             font-family: Rubik;
             font-style: normal;
             font-weight: 700;
             font-size: 30px;
             line-height: 100%;
             color: #24395A;
             padding-left: 15px;
           }
         }
         .second-div{
         width: 100%;
         margin-top: 25px;
         padding-left: 25px;
         padding-right: 25px;
         min-height: 83px;
           >h3{
             width: 100%;
             font-family: 'Montserrat-Regular';
             font-style: normal;
             font-weight: normal;
             font-size: 16px;
             line-height: 26px;
             text-align: justify;
             color: #24395A;
           }
          
         }
         .third-div{
           display: flex;
           flex-wrap: wrap;
           margin-top: 25px;
           justify-content: flex-start;
           padding-left: 25px;
           padding-right: 20px;
           border-bottom: 1px solid #ECECEC;
           padding-bottom: 25px;
           justify-content: space-between;
           >div{
             width: 153px;
             height: auto;
             display: flex;
             margin-top: 15px;
             >img{
             }
             >span{
               font-family: Rubik;
               font-style: normal;
               font-weight: normal;
               font-size: 18px;
               line-height: 22px;
               color: #3A3A3A;
               padding-left: 15px;
               margin: auto;
               margin-left: -6px;
             }
           }
         }
         .four-div{
           display: flex;
           margin-top: 25px;
           padding-left: 25px;
           padding-right: 25px;
           >div:first-child{
             width: 60%;
             display: flex;
             >h1{
               font-family: Rubik;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 80%;
      text-align: center;
      text-transform: uppercase;
      color: #24395A;
      margin: 0px;
      padding-top: 10px;
             }
             >span{
               font-family: Montserrat-Regular;
               font-style: normal;
               font-weight: normal;
               font-size: 14px;
               line-height: 62%;
               display: flex;
               align-items: center;
               text-transform: lowercase;
               color: rgba(36, 57, 90, 0.6);
               padding-left: 13px;
               padding-top: 0px;
               line-height: 16px;
             }
           }
           >div:last-child{
             width: 40%;
             display: flex;
             justify-content: flex-end;
             >button{
               width: 174px;
               height: 41px;
               left: 471px;
               top: 2492px;
               background: #24395A;
               border-radius: 3px;
               font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      
      color: #FFFFFF;
             }
           }
         }
       }
      }
      .card-button{
       display: flex;
       -webkit-box-align: center;
       align-items: center;
       -webkit-box-pack: center;
       justify-content: center;
       flex-direction: column;
       margin-top:35px;
       >button{
         height: 50px;
         width: 250px;
         white-space: nowrap;
         border: none;
         -webkit-letter-spacing: 1px;
         -moz-letter-spacing: 1px;
         -ms-letter-spacing: 1px;
         letter-spacing: 1px;
         display: inline-block;
         -webkit-align-items: center;
         -webkit-box-align: center;
         -ms-flex-align: center;
         align-items: center;
         -webkit-box-pack: center;
         -webkit-justify-content: center;
         -ms-flex-pack: center;
         justify-content: center;
         font-family: Rubik;
         font-style: normal;
         font-weight: 500;
         font-size: 22px;
         line-height: 26px;
         color: #FD7175;
         cursor: pointer;
         background-color: rgba(253, 113, 117, 0.1);
         border-radius: 3px;
         margin: 0px;
         &:hover{
           background-color: #FD7175;
           color: #FFFFFF;
         }
       }   
           }
    }

`;

const TestNew = styled.div`
display: flex;
.card-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
 .card-data{
   width: 550px;
   height: 460px;
   margin:  15px 0px 15px 50px;
   border-radius: 6px;
   background: ${({bgColor}) => bgColor};
  //  @media(max-width: 500px){
  //    width: 100%;
  //    margin:  15px 30px 15px 30px;
  //  }
  //  @media(max-width: 720px){
  //    width: 100%;
  //    margin:  15px 30px 15px 30px;
  //  }
  //  @media(max-width: 1000px){
  //    width: 100%;
  //    margin:  15px 30px 15px 30px;
  //  }
   .first-div{
     width: 100%;
     display: flex;
     padding-left: 25px;
     padding-right: 20px;
     
     margin-top: 25px;
     >img{
   
     }
     >h1{
       font-family: Rubik;
       font-style: normal;
       font-weight: 700;
       font-size: 30px;
       line-height: 80%;
       color: #24395A;
       padding-left: 15px;
     }
     >h3{
       font-family: Rubik;
       font-style: normal;
       font-weight: 700;
       font-size: 30px;
       line-height: 80%;
       color: #24395A;
       padding-left: 15px;
     }
   }
   .second-div{
   width: 100%;
   margin-top: 25px;
   padding-left: 25px;
   padding-right: 25px;
   min-height: 156px;
     >h3{
       width: 100%;
       font-family: 'Montserrat-Regular';
       font-style: normal;
       font-weight: normal;
       font-size: 16px;
       line-height: 26px;
       text-align: justify;
       color: #24395A;
     }
     >p{
       width: 100%;
       font-family: 'Montserrat-Regular';
       font-style: normal;
       font-weight: normal;
       font-size: 16px;
       line-height: 26px;
       text-align: justify;
       color: #24395A;
     }
    
   }
   .third-div{
     display: flex;
     flex-wrap: wrap;
     margin-top: 10px;
     justify-content: flex-start;
     padding-left: 25px;
     padding-right: 20px;
     border-width: 500px;
     border-bottom: 1px solid #ECECEC;
     padding-bottom: 25px;
     justify-content: space-between;

     >div{
       width: 166.66px;
       height: auto;
       display: flex;
       margin-top: 15px;
       >img{
         // width:100px;
       }
       >span{
         font-family: Rubik;
         font-style: normal;
         font-weight: normal;
         font-size: 18px;
         line-height: 22px;
         color: #3A3A3A;
         padding-left: 15px;
         margin: auto;
         margin-left: -6px;
       }
       >p{
         font-family: Rubik;
         font-style: normal;
         font-weight: normal;
         font-size: 18px;
         line-height: 22px;
         color: #3A3A3A;
         padding-left: 15px;
         margin: auto;
         margin-left: -6px;
       }
     }
     }
   }
   .four-div{
     display: flex;
     padding-left: 25px;
     padding-right: 25px;
     margin-top: 25px;
     >div:first-child{
       width: 60%;
       display: flex;
       >p{
         font-family: Rubik;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 80%;
text-align: center;
text-transform: uppercase;
color: #24395A;
margin:0px;
padding-top: 10px;
       }
       >h1{
         font-family: Rubik;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 80%;
text-align: center;
text-transform: uppercase;
color: #24395A;
margin:0px;
padding-top: 10px;
       }  >h2{
        font-family: Rubik;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 80%;
text-align: center;
text-transform: uppercase;
color: #24395A;
margin:0px;
padding-top: 10px;
      }
       >span{
         font-family: Montserrat-Regular;
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 62%;
         display: flex;
         align-items: center;
         text-transform: lowercase;
         color: rgba(36, 57, 90, 0.6);
         padding-left: 13px;
         padding-top: 10px;
       }
     }
     >div:last-child{
       width: 40%;
       display: flex;
       justify-content: flex-end;
       >button{
         width: 174px;
         height: 41px;
         left: 471px;
         top: 2492px;
         background: #24395A;
         border-radius: 3px;
         font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
       }
     }
   }
 }
}
.card-button{
 display: flex;
 -webkit-box-align: center;
 align-items: center;
 -webkit-box-pack: center;
 justify-content: center;
 flex-direction: column;
 margin-top:35px;
 >button{
   height: 50px;
   width: 250px;
   white-space: nowrap;
   border: none;
   -webkit-letter-spacing: 1px;
   -moz-letter-spacing: 1px;
   -ms-letter-spacing: 1px;
   letter-spacing: 1px;
   display: inline-block;
   -webkit-align-items: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   font-family: Rubik;
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   color: #FD7175;
   cursor: pointer;
   background-color: rgba(253, 113, 117, 0.1);
   border-radius: 3px;
   &:hover{
     background-color: #FD7175;
     color: #FFFFFF;
   }
 }   
     }

     @media(max-width: 1200px){
      display: flex;
      .card-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
       .card-data{
         width: 100%;
         height: auto;
         margin:  50px 0px 0px 0px;
         border-radius: 6px;
         padding-bottom: 25px;
         background: ${({bgColor}) => bgColor};
         .first-div{
           width: 100%;
           display: flex;
           padding-left: 25px;
           padding-right: 20px;
           
           margin-top: 25px;
           >img{
         
           }
           >h1{
             font-family: Rubik;
             font-style: normal;
             font-weight: 700;
             font-size: 30px;
             line-height: 100%;
             color: #24395A;
             padding-left: 15px;
           }
         }
         .second-div{
         width: 100%;
         margin-top: 25px;
         padding-left: 25px;
         padding-right: 25px;
         min-height: 156px;
           >h3{
             width: 100%;
             font-family: 'Montserrat-Regular';
             font-style: normal;
             font-weight: normal;
             font-size: 16px;
             line-height: 26px;
             text-align: justify;
             color: #24395A;
           }
          
         }
         .third-div{
           display: flex;
           flex-wrap: wrap;
           margin-top: 10px;
           justify-content: flex-start;
           padding-left: 25px;
           padding-right: 20px;
           border-width: 500px;
           border-bottom: 1px solid #ECECEC;
           padding-bottom: 25px;
           justify-content: space-between;
      
           >div{
             width: 166.66px;
             height: auto;
             display: flex;
             margin-top: 15px;
             >img{
      
             }
             >span{
               font-family: Rubik;
               font-style: normal;
               font-weight: normal;
               font-size: 18px;
               line-height: 22px;
               color: #3A3A3A;
               padding-left: 15px;
               margin: auto;
               margin-left: -6px;
             }
           }
         }
         .four-div{
           display: flex;
           padding-left: 25px;
           padding-right: 25px;
           margin-top: 25px;
           >div:first-child{
             width: 60%;
             display: flex;
             >h1{
               font-family: Rubik;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 80%;
      text-align: center;
      text-transform: uppercase;
      color: #24395A;
      margin:0px;
      padding-top: 10px;
             }  >h2{
              font-family: Rubik;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 80%;
      text-align: center;
      text-transform: uppercase;
      color: #24395A;
      margin:0px;
      padding-top: 0px;
      display: flex;
      align-items: center;
            }
             >span{
               font-family: Montserrat-Regular;
               font-style: normal;
               font-weight: normal;
               font-size: 14px;
               line-height: 62%;
               display: flex;
               align-items: center;
               text-transform: lowercase;
               color: rgba(36, 57, 90, 0.6);
               padding-left: 13px;
               padding-top: 0px;
               line-height: 16px;
             }
           }
           >div:last-child{
             width: 40%;
             display: flex;
             justify-content: flex-end;
             >button{
               width: 174px;
               height: 41px;
               left: 471px;
               top: 2492px;
               background: #24395A;
               border-radius: 3px;
               font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      
      color: #FFFFFF;
             }
           }
         }
       }
      }
      .card-button{
       display: flex;
       -webkit-box-align: center;
       align-items: center;
       -webkit-box-pack: center;
       justify-content: center;
       flex-direction: column;
       margin-top:35px;
       >button{
         height: 50px;
         width: 250px;
         white-space: nowrap;
         border: none;
         -webkit-letter-spacing: 1px;
         -moz-letter-spacing: 1px;
         -ms-letter-spacing: 1px;
         letter-spacing: 1px;
         display: inline-block;
         -webkit-align-items: center;
         -webkit-box-align: center;
         -ms-flex-align: center;
         align-items: center;
         -webkit-box-pack: center;
         -webkit-justify-content: center;
         -ms-flex-pack: center;
         justify-content: center;
         font-family: Rubik;
         font-style: normal;
         font-weight: 500;
         font-size: 22px;
         line-height: 26px;
         color: #FD7175;
         cursor: pointer;
         background-color: rgba(253, 113, 117, 0.1);
         border-radius: 3px;
         &:hover{
           background-color: #FD7175;
           color: #FFFFFF;
         }
       }   
           }
     }

`;

const  ButtonToggle = styled.div`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.body {
  background-color: #155FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 50px;
  margin: auto;
  font-family: sans-serif;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: #155FFF;
  position: relative;
  padding-right: 120px;
  position: relative;

  &:before {
    content: "Video";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(120px);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 15px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: #fff;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}

`;

const  Toggle = styled.div`

>div:last-child{
  display: flex;
  >div{
    width: 204px;
    height: 50px;
  }
}

`;

const  ContentWrapper = styled.div`

    display: flex;
    width: 100%;
    flex-wrap: wrap;

`;


export { TopPanel, Button, TopPanelContent, RowResponsiveWrapper, MiddlePanel, MiddlePanelSecond, CoreFeatureSection, Resources, MainResources, WellBeing, Button2, MenuListV2, AwarenessSection, TabData, TabsContainer, CustomListItemV2, FooterSection, WrapperContainer, RatingsContainer, ResourcesWrapper, RowWrapper, ButtonGetStarted, ShowLogos, ButtonLearnMore, OverFooter, Divider, ShowLogosDivider, PaddingContainer, RatingWrapper, Wrapper, ResponsiveTestimonialTab, RatingsContainers, TabsContainerTestimonial, MenuListV2Main, CustomListItemV2Main, GlobalMainWrapper, CoreWrapper, TabsContainerV2, CustomList,ButtonLearnMoree, IntroTextContainer, AddOnContainer, AddOnCard, QuestionCard, QuestionContainer, CoreWrapperr, MainContent, Section, TeamTabContainer, Table,TableContainer ,TableHeader, CoreWrapperrNew, Test, TestNew, ButtonToggle, Toggle, TableLast, ContentWrapper, AddOnCardMobile};
